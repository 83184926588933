
















































































































import axios from 'axios';
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { Fiat, FiatType, Org } from '@/api-svc-types';
import { pascalToSnake, snakeToPascal } from '@/components/rules/utilities';
import UiAlert from '@/components/ui/UiAlert.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import { store } from '@/store';
import { getCurrencyFromEnum } from '@/utils/coinUtils';
import { getEndpointUrl } from '@/utils/endpointUrlUtil';
import { toTitleCase } from '@/utils/stringUtils';

import { baConfig } from '../../../config';
import { CoreSvcOrgshdlUpdateOrgAdminSettingsInput, DefaultApi } from '../../../generated/core-svc';
import { BaseVue } from '../../BaseVue';
import AccountingSettings from '../../components/org/AccountingSettings.vue';
import API from '../../components/org/API.vue';
import Billing from '../../components/org/Billing.vue';
import Connections from '../../components/org/Connections.vue';
import Invitees from '../../components/org/Invitees.vue';
import Invoicing from '../../components/org/Invoicing.vue';
import OrgWallets from '../../components/org/OrgWallets.vue';
import Security from '../../components/org/Security.vue';
import Taxes from '../../components/org/Taxes.vue';
import TaxStrategy from '../../components/org/TaxStrategy.vue';
import UserList from '../../components/org/UserList.vue';

interface EngineVersion {
  label: string;
  id: number;
  disabled: boolean;
  tooltip: string;
}

enum OrgViewTab {
  OrgSettings,
  Users,
  Invitations,
  Connections,
  Accounting,
  OrgWallets,
  Api,
  Billing,
  Security,
  Invoicing,
  Taxes,
  TaxStrategy,
}

@Component({
  components: {
    AccountingSettings,
    API,
    OrgWallets,
    UserList: UserList,
    Invitees: Invitees,
    Connections: Connections,
    Billing,
    Security,
    Invoicing,
    Taxes,
    TaxStrategy,
    UiAlert,
    UiSelect2,
    UiTextEdit,
    UiCheckbox,
    UiButton,
  },
})
export default class OrgSettings extends BaseVue {
  @Prop()
  public readonly appRefs: unknown;

  public tab = OrgViewTab.OrgSettings;
  public valid = true;
  public rules = {
    required: (value: unknown) => !!value || 'Required.',
  };

  public isLoading = false;
  public isSaving = false;

  // Org details variables
  public org: Org | null = null;
  public exchanges: unknown[] = [];
  public name?: string | null = null;
  public timezone?: string | null = null;
  public useOrgTimezone: boolean | null = null;
  public preferredExchange: string | null = null;
  public baseCurrency: string | null = null;
  public engineVersion = 1.0;
  public etag = '';

  public OrgViewTab = OrgViewTab; // Expose enum for use in template
  public showXeroAlert = true;
  public engineVersions: EngineVersion[] = [
    { label: 'v1.0', id: 1.0, disabled: true, tooltip: '|Balance Report, Gain & Loss Report, Inventory Views' },
    {
      label: 'v1.2',
      id: 1.2,
      disabled: false,
      tooltip: 'Impairment|Fixed a bug on the Balance Report and Gain and Loss Report to get T+0 11-12PM price',
    },
    {
      label: 'v2.0',
      id: 2.0,
      disabled: true,
      tooltip:
        'Wallet Level Inventories and Inventory Groups|Fixes several issues with date time resolution for end of day detection, which could cause minor variances from v1',
    },
    { label: 'v2.1', id: 2.1, disabled: true, tooltip: 'GAAP Fair Value|' },
    {
      label: 'v2.2',
      id: 2.2,
      disabled: true,
      tooltip:
        '|Updates the impairment engine to ensure use of configured pricing methodology instead of defaulting to close of day.',
    },
    {
      label: 'v2.3',
      id: 2.3,
      disabled: true,
      tooltip:
        '|Fixes an issue where disposals are processed before acquisitions on trades where an acquired asset in a trade is disposed of in that same transaction and there is not enough quantity to dispose of.',
    },
    {
      label: 'v2.4',
      id: 2.4,
      disabled: true,
      tooltip: '|Allows users to choose to process acquisitions before disposals in inventory views.',
    },
    {
      label: 'v2.5',
      id: 2.5,
      disabled: true,
      tooltip:
        '|Improved handling of complex acquisition and disposition tax lots for specific identification and wrapped tokens for wallet level inventory.',
    },
    {
      label: 'v2.6',
      id: 2.6,
      disabled: false,
      tooltip: '|Advanced DeFi Categorization missing wallet id will perform lookup for wallet level inventory.',
    },
  ];

  public engineVersionInfo = '';
  public engineVersionInfoTitle = '';
  public disabledEngineVersion = false;

  public capitalizeFees: boolean | null = null;
  public readonly feeCapitalizationOptions = [
    { id: 'fee_capitalization', label: 'Fee Capitalization' },
    { id: 'fee_expense', label: 'Fee Expense' },
  ];

  public get timezones() {
    return moment.tz.names();
  }

  public get fiats(): FiatType[] | undefined {
    return this.$store.state.fiats.fiats as FiatType[] | undefined;
  }

  public get user() {
    return this.$store.state.user;
  }

  public get newOrgDetails() {
    return this.$store.state.newOrgDetails;
  }

  public get suggestXeroConnection() {
    return (
      this.user?.loginMethod === 'xero' &&
      this.newOrgDetails?.orgId === this.org?.id &&
      this.newOrgDetails?.createdAt &&
      Date.now() - this.newOrgDetails.createdAt.valueOf() < 86400000 // Org was created less than 24 hrs ago
    );
  }

  public get isValid() {
    if (!this.timezone) {
      return false;
    }

    if (!this.baseCurrency) {
      return false;
    }

    return true;
  }

  // used in the select input
  public get selectedFeeCapitalization() {
    // get true or false either from the input value or the org data if input is not defined
    const capitalize = this.capitalizeFees ?? this.org?.taxConfig?.capitalizeTradingFees;

    // if neither was defined return the safe default value
    if (capitalize === undefined) return 'fee_capitalization';
    return capitalize ? 'fee_capitalization' : 'fee_expense';
  }

  // used in the select input
  public set selectedFeeCapitalization(inputValue) {
    this.capitalizeFees = inputValue === 'fee_capitalization';
  }

  /** Vue lifecycle hook for 'mounted' */
  public mounted() {
    // load the org details
    this._loadOrgData();
  }

  private getRequestHeaders(headers?: any) {
    const accessToken = this.$store.state.parentAuthToken || this.$store.state.authTokens?.accessToken.token;
    const retHeaders: any = headers ?? {};

    if (accessToken) {
      retHeaders.Authorization = `Bearer ${accessToken}`;
    }

    return retHeaders;
  }

  private async _loadOrgData() {
    this.isLoading = true;
    try {
      const cs = new DefaultApi(undefined, `${baConfig.api3Url}/v3`);
      const resp = await cs.httpOrgshdlOrgsHTTPHandlerGet(this.orgId, {
        withCredentials: true,
      });

      if (resp.status === 200) {
        const org = resp.data;
        this.name = org.name;
        this.timezone = org.timezone;
        this.useOrgTimezone = org.displayConfig?.useOrgTimezone ?? false;

        if (org.baseCurrency) {
          const foundBaseCurrency = this.fiats?.find((item) => String(item.id) === String(org.baseCurrency));

          if (foundBaseCurrency?.id) {
            this.baseCurrency = foundBaseCurrency?.id;
          }
        }

        this.etag = resp.headers.etag;
        this.capitalizeFees = org.taxConfig?.capitalizeTradingFees ?? false;
        // this.preferredExchange = org?.pricingSettings?.preferredExchange ?? null;
        // Set the default selected engine version -> v1.0
        const currentEngineVersion = org?.engineVersion ?? this.engineVersions[0].id;
        this.changeEngineVersion(this.engineVersions.find((v) => v.id === currentEngineVersion) as EngineVersion);
      } else {
        this.showErrorSnackbar('Problem loading org: ' + resp.data);
      }
    } catch (e) {
      this.showErrorSnackbar('Problem loading org: ' + (e as any).message);
    } finally {
      this.isLoading = false;
    }
  }

  public async save() {
    const cs = new DefaultApi(undefined, `${baConfig.api3Url}/v3`);
    const patch: CoreSvcOrgshdlUpdateOrgAdminSettingsInput = {
      name: this.name,
      capitalizeTradingFees: this.capitalizeFees,
      engineVersion: this.engineVersion,
      useOrgTimezoneForDisplay: this.useOrgTimezone,
    };

    const headers = this.getRequestHeaders({ 'if-match': this.etag });

    const resp = await cs.httpOrgshdlOrgsHTTPHandlerUpdateOrgAdminSettings(
      this.$store.state.currentOrg.id,
      this.etag,
      patch,
      { withCredentials: true, headers }
    );
    if (resp.status === 200) {
      this.showSuccessSnackbar('Org Saved');
      await this._loadOrgData();
    } else {
      this.showErrorSnackbar('Failed to save'); // check 412 / 425
    }
  }

  public clear() {
    this.$apollo.queries.org.refetch();
    this._loadOrgData();
  }

  public switchTo(tab: OrgViewTab) {
    if (tab === OrgViewTab.Invitations) {
      // TODO: This is a bug, this.$refs.invitees is always undefined. Existing behavior.
      (this.$refs.invitees as any).refresh();
      this.tab = OrgViewTab.Invitations;
    }
  }

  public async handleXeroAlertAction(action: string) {
    switch (action) {
      case 'Connect now': {
        const url = this.getFunctionsBaseUrl() + 'app/org/connections/connect?service=xero';
        const resp = await axios({
          method: 'POST',
          url,
          withCredentials: true,
          data: { orgId: this.$store.state.currentOrg.id },
        });
        if (resp.status === 200) {
          window.location.href = resp.data.connectUrl;
        }
        break;
      }
      case 'Later':
        this.showXeroAlert = false;
        break;
    }
  }

  public changeEngineVersion(selectedEngine: EngineVersion) {
    this.engineVersion = selectedEngine.id;

    if (selectedEngine.tooltip) {
      const info = selectedEngine.tooltip.split('|');
      this.engineVersionInfoTitle = selectedEngine.label + (info[0] ? ' - ' + info[0] : '');
      this.engineVersionInfo = info[1];
    }
  }

  @Watch('$route.params.tab', { immediate: true })
  onUrlTabChange(newTab?: string) {
    const newTabPascal = newTab ? (snakeToPascal(newTab) as keyof typeof OrgViewTab) : undefined;
    if (newTabPascal !== undefined && OrgViewTab[newTabPascal]) {
      this.tab = OrgViewTab[newTabPascal];
    } else {
      this.tab = OrgViewTab.OrgSettings;
    }
  }

  @Watch('tab')
  onTabChange(newTab: OrgViewTab) {
    const tabNameSnake = pascalToSnake(OrgViewTab[newTab]);
    if (this.$router.currentRoute.params.tab !== tabNameSnake) {
      if (newTab === OrgViewTab.OrgSettings) {
        this.$router.push({ name: 'Settings' });
      } else {
        this.$router.push({ name: 'Settings', params: { tab: tabNameSnake } });
      }
    }
  }

  @Watch('$store.state.currentOrg')
  onOrgChange() {
    // reload data when org changes
    this._loadOrgData();
  }
}
